import $ from 'jquery';

export default function loadRecaptchaWhenInView (): void {
  let isRecaptchaLoaded = false;
  const intersectionObserverOptions: IntersectionObserverInit = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1,
  };
  const loadCaptchaWhenInView = (
    entries: IntersectionObserverEntry[],
  ): void => {
    if (isRecaptchaLoaded === false) {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          if (document.querySelector('script#google_recaptcha')) {
            return;
          }

          const recaptchaScript = document.createElement('script');
          document.body.appendChild(recaptchaScript);
          recaptchaScript.id = 'google_recaptcha';
          recaptchaScript.onload = function (): void {
            $(
              'form[data-recaptcha="1"] button[data-disable-after-captcha="0"]',
            ).attr('disabled', null);
          };
          recaptchaScript.src =
            `https://www.google.com/recaptcha/api.js?render=${document.body.dataset.siteKey}`;
          recaptchaScript.defer = true;
          isRecaptchaLoaded = true;
        }
      });
    }
  };
  const observer = new IntersectionObserver(
    loadCaptchaWhenInView,
    intersectionObserverOptions,
  );
  const formsWithCaptcha = document.querySelectorAll('[data-recaptcha="1"]');
  formsWithCaptcha.forEach((box: Element) => {
    observer.observe(box);
  });
}
