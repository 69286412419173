export const REVIEW_MINIMUM_TEXT_LENGTH = 50;
export const REVIEW_MAXIMUM_TEXT_LENGTH = 5000;
export const REVIEW_CONTEST_MINIMUM_TEXT_LENGTH_ELIGIBLE = 1500;
export const REVIEW_MIN_RATING = 1;
export const REVIEW_MAX_RATING = 5;

export const PASSWORD_MINIMUM_LENGTH = 8;

export const DISCOUNT_CODE_MINIMUM_LENGTH = 2;
export const DISCOUNT_CODE_MAXIMUM_LENGTH = 50;
