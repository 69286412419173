import 'bootstrap/js/dist/modal';

import successIcon from './components/successIcon';
import errorIcon from './components/errorIcon';
import infoIcon from './components/infoIcon';
import $ from 'jquery';
import {AlertInterface} from '@/api/generated';

const Alert = {
  modalContainer: $('#alertModalContainer'),
  modalId: '#alertModal',
  message: $('#alertMessage'),
  successIcon: successIcon,
  errorIcon: errorIcon,
  infoIcon: infoIcon,
  addMessage (message: string): void {
    $('#alertMessage').append(message);
  },
  replaceMessage (message: string): void {
    $('#alertMessage').html(message);
  },
  changeType (
    alertType: AlertInterface.alertType|'info'|  'success'| 'danger'|  'warning'|  'information'|  'notice'|  'error'
  ): void {
    const modalDialog = $('#alertModal').find('.modal-dialog');
    if (modalDialog.hasClass('modal-success')) {
      modalDialog.removeClass('modal-success');
    }
    if (modalDialog.hasClass('modal-info')) {
      modalDialog.removeClass('modal-info');
    }
    if (modalDialog.hasClass('modal-danger')) {
      modalDialog.removeClass('modal-danger');
    }
    if (modalDialog.hasClass('modal-error')) {
      modalDialog.removeClass('modal-error');
    }
    if (modalDialog.hasClass('modal-notice')) {
      modalDialog.removeClass('modal-notice');
    }
    modalDialog.addClass(`modal-${alertType}`);

    const frame = $(Alert.modalId).find('.modal-frame');
    if (frame.hasClass('modal-notice')) {
      frame.removeClass('modal-notice');
    }
    if (frame.hasClass('modal-success')) {
      frame.removeClass('modal-success');
    }
    if (frame.hasClass('modal-danger')) {
      frame.removeClass('modal-danger');
    }
    if (frame.hasClass('modal-warning')) {
      frame.removeClass('modal-warning');
    }
    if (frame.hasClass('modal-info')) {
      frame.removeClass('modal-info');
    }
    frame.addClass(`modal-${alertType}`);
  },
  show (): void {
    $('#alertModal').modal('show');
  },
  hide (): void {
    $('#alertModal').modal('hide');
    // Alert.modal.modal("hide");
  },
  replace (content: string): void {
    $(Alert.modalId).replaceWith(content);
  },
};
$(document).ready(() => {
  if ($('body').data('hasAlert') === true) {
    Alert.show();
    setTimeout(function () {
      Alert.hide();
    }, 4000);
  }
});

export { Alert };
