import changeQuantityModal from './changeQuantityModal';
import CartItemsUpdateRequest from '../interface/CartItemsUpdateRequest';
import $ from 'jquery';

export default function quantityDown (
  itemId: string,
  productId: number,
  itemRequest: CartItemsUpdateRequest,
): void {
  // this should be universal
  const quantityInput = $(`input.quantity[data-item-id="${itemId}"]`);
  const newQty: number = parseInt(quantityInput.val() as string) - 1;
  itemRequest.items.forEach((item) => {
    if (item.productId === productId) {
      item.quantity = newQty;
    }
  });

  changeQuantityModal(itemRequest);
  if (newQty <= 1) {
    $('.minus.update-quantity').prop('disabled', true);
  }
  quantityInput.val(newQty);
}
