import { Alert } from './alert';
import $ from 'jquery';
import JQueryXHR from './interface/JQueryXHR';

class AjaxUpdate {
  private readonly form: JQuery | undefined;
  private response: JQueryXHR;

  constructor (response: JQueryXHR, form?: JQuery) {
    this.response = response;
    if (form) {
      this.form = form;
    }

    this.update();
  }

  private update (): void {
    if (
      typeof this.response.refreshPage !== 'undefined' &&
      this.response.refreshPage === true
    ) {
      location.reload();
    }

    if (this.form) {
      $(this.form).closest('.modal').modal('hide');
    }

    if (
      typeof this.response.elementToUpdate !== 'undefined' &&
      typeof this.response.contentUpdate !== 'undefined'
    ) {
      if ($(this.response.elementToUpdate).length > 0) {
        $(this.response.elementToUpdate).html(this.response.contentUpdate);
      } else {
        console.error(
          'You are trying to update an element that does not exists on page: ' +
          this.response.elementToUpdate,
        );
      }
    }

    if (
      typeof this.response.elementsToUpdate !== 'undefined' &&
      typeof this.response.contentsUpdate !== 'undefined'
    ) {
      for (let i = 0; i <= this.response.elementsToUpdate.length; i++) {
        if ($(this.response.elementsToUpdate[i]).length > 0) {
          $(this.response.elementsToUpdate[i]).html(
            this.response.contentsUpdate[i],
          );
        } else {
          console.error(
            'You are trying to update an element that does not exists on page: ' +
            this.response.elementsToUpdate[i],
          );
        }
      }
    }

    if (typeof this.response.message !== 'undefined') {
      Alert.replaceMessage(this.response.message);
    } else {
      Alert.replaceMessage('Pomyślnie zapisano dane.');
    }
    Alert.changeType('success');
    Alert.show();
  }
}

export { AjaxUpdate };
