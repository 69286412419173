import Route from '@/js/utils/route';
import { Site } from './site';
import { Alert } from './alert';
import { circleLoader } from './circleLoader';
import $ from 'jquery';
import jqXHR = JQuery.jqXHR;
import * as Cookies from 'es-cookie';

export default function newsletterSignup (email: string, token: string): void {
  void $.ajax({
    type: 'post',
    url: Route.get('frontApi.newsletterSignup'),
    headers: {'X-XSRF-TOKEN': Cookies.get('XSRF_TOKEN') ?? '' },
    data: {
      captcha: token,
      email: email,
      location: window.location.href,
    },
    dataType: 'json',
    success: function (response: { message?: string }) {
      Alert.replaceMessage(
        response.message || 'Pomyślnie zapisano do newslettera',
      );
      Alert.changeType('success');
      Alert.show();

      return true;
    },
    error: function (data: jqXHR) {
      const response = data.responseJSON as { message?: string };
      Alert.changeType('danger');
      Alert.replaceMessage(response.message || 'Wystąpił niespodziewany błąd.');
      circleLoader.hide();
      Alert.show();
      return false;
    },
    complete: function () {
      circleLoader.hide();

      return true;
    },
  });
}
