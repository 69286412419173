import $ from 'jquery';
import 'jquery.inputmask';
import { ValidationSettings } from './ValidationSettings';
import {
  INPUT_MASK_BANK_ACC,
  INPUT_MASK_BANK_ACC_OPTS,
  INPUT_MASK_PHONE,
  INPUT_MASK_PHONE_OPTS,
} from './const/constants';

function updateInputs (): void {
  const input = $(':input');
  input.inputmask();
  const ua = window.navigator.userAgent.toLowerCase();
  const isAndroid = ua.includes('android');

  if (!isAndroid) {
    $('.inputPhone').inputmask(INPUT_MASK_PHONE, INPUT_MASK_PHONE_OPTS);
  }
  $('.inputPostcode').inputmask('99-999');
  $('.inputBankAccount').inputmask(
    INPUT_MASK_BANK_ACC,
    INPUT_MASK_BANK_ACC_OPTS,
  );
}

$(function (): void {
  $(document).on('shown.bs.modal', '.modal', function () {
    updateInputs();
  });

  $.validator.setDefaults(ValidationSettings.getSettings());
  $.validator.addMethod(
    'customPhone',
    function (value, element): boolean {
      return ValidationSettings.validatePhone(value, element);
    },
    function (error, element): string {
      return ValidationSettings.validatePhoneMsg(error, element);
    },
  );
  $.validator.addMethod(
    'customCellPhone',
    function (value, element): boolean {
      return ValidationSettings.validateCellPhone(value, element);
    },
    function (error, element): string {
      return ValidationSettings.validateCellPhoneMsg(error, element);
    },
  );

  $.validator.addMethod(
    'polishPostcode',
    function (value): boolean {
      return ValidationSettings.validatePostcode(value);
    },
    function (error, element): string {
      return ValidationSettings.validatePostcodeMessage(error, element);
    },
  );

  $.validator.addMethod(
    'bankAccount',
    function (value): boolean {
      return ValidationSettings.validateBankAccount(value);
    },
    function (error, element): string {
      return ValidationSettings.validateBankAccountMsg(error, element);
    },
  );

  $.validator.addMethod(
    'nip',
    function (value): boolean {
      return ValidationSettings.validateNip(value);
    },
    function (error, element): string {
      return ValidationSettings.validateNipMsg(error, element);
    },
  );

  $.validator.addMethod(
    'passwordChars',
    function (value): boolean {
      return ValidationSettings.validatePasswordChars(value);
    },
    function (): string {
      return '';
    },
  );

  $.validator.addMethod(
    'emailFormat',
    function (value): boolean {
      return ValidationSettings.validateEmail(value);
    },
    function (): string {
      return 'Adres email jest w niewłaściwym formacie!';
    },
  );

  $.validator.addClassRules(ValidationSettings.getDefaultRules());

  updateInputs();
});
