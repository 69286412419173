// import Footer from './footer';
import 'bootstrap/js/src/popover';
import { Alert } from './alert';
import { Site } from './site';
import './inputs';
import './newsletter';
import Route from '@/js/utils/route';
import { circleLoader } from './circleLoader';
import $ from 'jquery';
import ErrorsObject from './interface/ErrorsObject';
import jqXHR = JQuery.jqXHR;
import URI from 'urijs';
import useWebcomponent from '@/ui/composable/useWebcomponent';

const lazyImgs: HTMLImageElement[] = document.querySelectorAll('[data-placeholder="image"]') as unknown as HTMLImageElement[];
const pageLayoutsContainers = document.querySelectorAll('.page-layouts-container');
window.addEventListener('load', async (event) => {
  if (pageLayoutsContainers.length > 0) {
    await useWebcomponent('ui-iframe')
  }
})
if (lazyImgs) {
  lazyImgs.forEach((lazyImg) => {
    if (!lazyImg.dataset.src) {
      return;
    }
    lazyImg.src = lazyImg.dataset.src;
    lazyImg.onload = () => {
      const imageWrapper = lazyImg.parentElement;
      if (!imageWrapper) return;
      imageWrapper.querySelector(
        '.-cmsd-lazy-placeholder',
      )?.remove();
    }
  })
}
function toggleBackToTop (): void {
  const backToTop = $('#backToTop');
  const uri = new URI(window.location.href);
  const indexOf = uri.hash().indexOf('#categories-menu');
  if (($(window).scrollTop() as number) > ($('body').height() as number) / 2 && 0 !== indexOf) {
    backToTop.removeClass('d-none');
    backToTop.addClass('visible');
  } else {
    backToTop.removeClass('visible');
    backToTop.addClass('d-none');
  }
}

$(window).bind('load', function () {
  $('body').removeClass('doc-loading');
  $(document).trigger('bodyLoading');
  toggleBackToTop();
});
$(function (): void {
  if (Site.isChecked === false) {
    Site.bodyClass();
  }

  const linksInJs = $('a.link-js-clickable');
  if (linksInJs.data('href')) {
    linksInJs.on('click', function (e) {
      window.location.href = $(this).data('href') as string;
    })
  }


  // Workaround for Safari back-page cache causing this value to not be updated.
  if (Site.isIos) {
    window.addEventListener('pageshow', function (event) {
      if (!event.persisted) {
        return;
      }
      Site.disableLoading();
    });
  }


  $('[data-toggle=popover]').popover();

  const categoriesDropdown = $('.categories-dropdown');
  categoriesDropdown.on('shown.bs.dropdown', function () {
    $('#megaMenuTogglerTop').html(
      '<svg class="icon menu-opened-icon ">\n' +
      '    <use xlink:href="#menu-opened"></use>\n' +
      '</svg>',
    );
  });

  categoriesDropdown.on('hidden.bs.dropdown', function () {
    $('#megaMenuTogglerTop').html(
      '<svg class="icon menu-closed-icon ">\n' +
      '    <use xlink:href="#menu-closed"></use>\n' +
      '</svg>',
    );
  });

  $('.categories-dropdown a').on('click', function () {
    document.location.href = $(this).attr('href') as string;
  });

  $(window).on('scroll', function (): void {
    toggleBackToTop();
    if (window.scrollY < 57) {
      $('.categories-dropdown-menu-desktop').dropdown('hide');
    }
  });

  $(window).on('resize', function (): void {
    Site.isTouchDevice = window.matchMedia('(pointer: none)').matches || false;
    Site.isMouseDevice =
      window.matchMedia('(any-hover: hover)').matches || true;
  });

  $('#backToTop').on('click', function (e): void {
    e.preventDefault();
    $('html, body').animate(
      {
        scrollTop: 0,
      },
      1000,
    );
  });

  $('#newsletterSignUpButton').on('click', function (e) {
    e.preventDefault();
    $('footer #newsletterForm').trigger('submit');
  });

  $('.nav-item-expandable').on( 'mouseenter', function (e): void {
    setBannerVisibility(e);
    navItemMouseEnterHandler(e);
  })
  $('.nav-item-expandable').on( 'mouseleave', function (e): void {
    navItemMouseLeaveHandler(e);
  })
  $('.expandable-menu-category-list-item').on( 'mouseenter', function (e): void {
    expandableMenuListCategoryMouseEnterHandler(e);
  })
  $('.expandable-menu-product-list-item').on( 'mouseenter', function (e): void {
    expandableMenuListProductMouseEnterHandler(e);
  })
});

function setBannerVisibility (e: JQuery.MouseEnterEvent<HTMLElement, undefined, HTMLElement, HTMLElement>): void {
  const expandableMenu = $(e.currentTarget).find('.expandable-menu');
  const expandableBannerContainer = expandableMenu.find('.expandable-banner');
  const bannerContainer = expandableBannerContainer.find('a');
  if (bannerContainer.length === 0) {
    expandableBannerContainer.addClass('hidden');
    return;
  }
  expandableBannerContainer.removeClass('hidden');
}

let timeout: null|number = null;

function navItemMouseEnterHandler (e: JQuery.MouseEnterEvent<HTMLElement, undefined, HTMLElement, HTMLElement>): void {
  if (timeout !== null) {
    window.clearTimeout(timeout);
  }
  $('.expandable-menu').removeClass('visible');
  $('.expandable-category').removeClass('visible');
  $('.expandable-banner').removeClass('visible');
  $('.expandable-menu-category-list-item').removeClass('active');
  $(e.currentTarget).find('.expandable-menu').addClass('visible');
  $(e.currentTarget).find('.expandable-product.visible').removeClass('visible');
  $(e.currentTarget).find('.expandable-product.default-visible').addClass('visible');
  $('.expandable-menu-product-list-item.default-active').addClass('active');
}

function navItemMouseLeaveHandler (e: JQuery.MouseLeaveEvent<HTMLElement, undefined, HTMLElement, HTMLElement>): void {
  timeout = window.setTimeout(function () {
    $(e.currentTarget).find('.expandable-menu').removeClass('visible');
    $('.expandable-menu-product-list-item').removeClass('active');
    $('.expandable-menu-product-list-item.default-active').addClass('active');
  }, 300);
}

function expandableMenuListCategoryMouseEnterHandler (e: JQuery.MouseEnterEvent<HTMLElement, undefined, HTMLElement, HTMLElement>): void {
  $('.expandable-menu-category-list-item').removeClass('active');
  $(e.currentTarget).addClass('active')
  $('.expandable-category').removeClass('visible');
  if ($(e.currentTarget).find('.expandable-category').length > 0) {
    $('.expandable-banner').addClass('visible');
    $(e.currentTarget).find('.expandable-category').addClass('visible');
  } else {
    $('.expandable-banner').removeClass('visible');
  }
}
function expandableMenuListProductMouseEnterHandler (e: JQuery.MouseEnterEvent<HTMLElement, undefined, HTMLElement, HTMLElement>): void {
  $('.expandable-menu-product-list-item').removeClass('active');
  $(e.currentTarget).addClass('active')
  $('.expandable-product').removeClass('visible');
  $(e.currentTarget).find('.expandable-product').addClass('visible');
}

export function showAlert (
  data?: jqXHR,
  validator?: JQueryValidation.Validator,
): void {
  if (!data) {
    return;
  }

  const response = data.responseJSON as {
    message?: string;
    alertModal?: string;
    errors: Array<string>;
  };

  if (typeof response.alertModal !== 'undefined') {
    Alert.replace(response.alertModal);
    Alert.changeType('danger');
    Alert.show();
  } else if (validator !== null && typeof response.errors !== 'undefined') {
    const errors: ErrorsObject = {};
    $.each(response.errors, function (fieldName, errorMessages) {
      $.each(errorMessages, function (index, messageText) {
        if ($(`input[name=${fieldName.toString()}]`).length > 0) {
          errors[fieldName.toString()] = messageText;
        } else {
          Alert.replaceMessage(messageText);
          Alert.changeType('danger');
          Alert.show();
        }
      });
      if (typeof validator !== 'undefined') {
        validator.showErrors(errors);
      }
    });
  }
}
