import { Alert } from './alert';
import { circleLoader } from './circleLoader';
import { AjaxUpdate } from './AjaxUpdate';
import { ParseError, parsePhoneNumberFromString } from 'libphonenumber-js/max';
import {
  PASSWORD_MINIMUM_LENGTH,
  REVIEW_MAX_RATING,
  REVIEW_MAXIMUM_TEXT_LENGTH,
  REVIEW_MIN_RATING,
  REVIEW_MINIMUM_TEXT_LENGTH,
} from './common/constants';
import $ from 'jquery';
import ValidationRule from './interface/ValidationRule';
import Validator = JQueryValidation.Validator;
import jqXHR = JQuery.jqXHR;
import * as Cookies from 'es-cookie';

class Validation {
  static addMessageToInput (
    invalidInput: JQuery,
    key: string,
    value: string,
  ): void {
    invalidInput.addClass('is-invalid');
    invalidInput.removeClass('is-valid');
    const label = $(`label[for=${String(key)}]`);
    const validationMsg = $(
      `<div class="help-block is-invalid">` + value + `</div>`,
    );
    validationMsg.insertAfter(label);
  }

  static removeMessageFromInput (invalidInput: JQuery, key: string): void {
    const label = $(`label[for=${String(key)}]`);
    label.siblings('.help-block').html('');
  }
}

class ValidationSettings {
  public static getBaseSettings (): JQueryValidation.ValidationOptions {
    return {
      errorClass: 'is-invalid',
      validClass: 'is-valid',
      errorElement: 'div',
      focusInvalid: true,
      invalidHandler: function (
        event: JQueryEventObject,
        validator: Validator,
      ): void {
        if ($(event).data('recaptcha') === '1') {
          grecaptcha.reset();
        }
        validator.focusInvalid();
      },
      highlight: function (
        element: HTMLElement,
        errorClass: string,
        validClass: string,
      ): void {
        $(element).addClass(errorClass).removeClass(validClass);
      },
      unhighlight: function (
        element: HTMLElement,
        errorClass: string,
        validClass: string,
      ): void {
        if (
          $(element).hasClass('optional') &&
          $.trim($(element).val() as string) === ''
        ) {
          $(element).removeClass(errorClass).removeClass(validClass);
        } else if ($(element).hasClass('hideValidation')) {
          $(element).removeClass(errorClass).removeClass(validClass);
        } else {
          $(element).removeClass(errorClass).addClass(validClass);
        }
      },
      errorPlacement: function (error: JQuery, element: JQuery): void {
        // Add the `help-block` class to the error element
        error.addClass('help-block');

        if (element.prop('type') === 'checkbox') {
          error.insertAfter(element.parent('label'));
        } else {
          error.insertAfter(element.siblings('label'));
        }
      },
      ignore: ':hidden:not(.force-validation)',
    };
  }

  static getSettings (): JQueryValidation.ValidationOptions {
    return {
      ...this.getBaseSettings(),
      submitHandler: function (form: HTMLFormElement): void {
        $(form).validate(ValidationSettings.getSettings());

        $(form)
          .find('input[name=userPhone]')
          .each(function (index, element) {
            if (typeof element.inputmask !== 'undefined') {
              $(element).val(element.inputmask.unmaskedvalue());
            }
          });

        $(form)
          .find('input[name=addressPhone]')
          .each(function (index, element) {
            if (typeof element.inputmask !== 'undefined') {
              $(element).val(element.inputmask.unmaskedvalue());
            }
          });

        if ($(form).data('ajax') === 1) {
          void $.ajax({
            url: $(form).attr('action'),
            type: $(form).attr('method'),
            dataType: 'json',
            data: $(form).serialize(),
            headers: {'X-XSRF-TOKEN': Cookies.get('XSRF_TOKEN') ?? '' },
            success: function (response) {
              new AjaxUpdate(response, $(form) as JQuery<HTMLElement>);
              return true;
            },
            error: function (data: jqXHR) {
              const response = data.responseJSON as {
                message?: string;
                errors: Record<number, string>;
                status: number;
              };
              Alert.changeType('danger');
              // unprocessable entity, bad request
              if (
                typeof response !== 'undefined' &&
                (response.status === 422 || response.status === 400)
              ) {
                if (response.message == 'The given data was invalid.') {
                  response.message = 'W formularzu wystąpił błąd!';
                }
                Alert.replaceMessage(response.message || '');
                $.each(response.errors, function (key, value) {
                  const invalidInput = $(`input[name=${String(key)}]`);
                  invalidInput.on('change', function () {
                    Validation.removeMessageFromInput(
                      invalidInput,
                      String(key),
                    );
                  });
                  Validation.addMessageToInput(
                    invalidInput,
                    String(key),
                    value,
                  );
                });
              } else {
                Alert.replaceMessage(response.message || 'Wystąpił błąd!');
              }

              Alert.show();

              return false;
            },
            complete: function () {
              circleLoader.hide();
            },
          });
        } else {
          if ($(form).data('submitted')) {
            return;
          } else {
            $(form).data('submitted', 'true');
          }

          form.submit();
        }
      },
    };
  }

  static getDefaultRules (): Record<string, ValidationRule> {
    return {
      validateName: {
        normalizer: function (value: string): string {
          return $.trim(value);
        },
        required (): boolean {
          return true;
        },
        minlength: 2,
      },
      validateCellPhone: {
        required (element: JQuery | undefined): boolean {
          if (typeof element === 'undefined') {
            return false;
          }
          if ($(element).attr('required')) {
            return true;
          }
          return false;
        },
        customCellPhone: true,
      },
      validatePhone: {
        required (element: JQuery | undefined): boolean {
          if (typeof element === 'undefined') {
            return false;
          }
          if ($(element).attr('required')) {
            return true;
          }
          return false;
        },
        customPhone: true,
      },
      validateLogin: {
        email: true,
        required (): boolean {
          return true;
        },
        minlength: 5,
      },
      validateEmail: {
        required (): boolean {
          return true;
        },
        minlength: 5,
        emailFormat: true
      },
      validateCity: {
        normalizer: function (value: string): string {
          return $.trim(value);
        },
        required (): boolean {
          return true;
        },
        minlength: 2,
      },
      validateNip: {
        nip: true,
      },
      validateApartment: {
        normalizer: function (value: string): string {
          return $.trim(value);
        },
        required (): boolean {
          return true;
        },
        minlength: 1,
      },
      validatePostcode: {
        normalizer: function (value: string): string {
          return $.trim(value);
        },
        required (): boolean {
          return true;
        },
        minlength: 5,
        polishPostcode: true,
      },
      validateReview: {
        normalizer: function (value: string): string {
          return $.trim(value);
        },
        required: function (element: JQuery | undefined): boolean {
          if (typeof element === 'undefined') {
            return false;
          }

          if ($(element).attr('required')) {
            return true;
          }

          return false;
        },
        minlength: REVIEW_MINIMUM_TEXT_LENGTH,
        maxlength: REVIEW_MAXIMUM_TEXT_LENGTH,
      },
      validateReviewRate: {
        required (): boolean {
          return true;
        },
        min: REVIEW_MIN_RATING,
        max: REVIEW_MAX_RATING,
      },
      validatePassword: {
        required (): boolean {
          const currentPasswordInput = $('#userPasswordCurrent');

          return currentPasswordInput.length > 0 && currentPasswordInput.val() !== '';
        },
        minlength: PASSWORD_MINIMUM_LENGTH,
      },
      validateNewPassword: {
        required (): boolean {
          const currentPasswordInput = $('#userPasswordCurrent');

          return currentPasswordInput.length > 0 && currentPasswordInput.val() !== '';
        },
        passwordChars: true,
      },
      validatePasswordConfirmation: {
        required (): boolean {
          const currentPasswordInput = $('#userPasswordCurrent');

          return currentPasswordInput.length > 0 && currentPasswordInput.val() !== '';
        },
        minlength: PASSWORD_MINIMUM_LENGTH,
        equalTo: '#userPassword',
      },
      validateResetPasswordConfirmation: {
        required (): boolean {
          const currentPasswordInput = $('#password_confirmation');

          return currentPasswordInput.length > 0 && currentPasswordInput.val() !== '';
        },
        minlength: PASSWORD_MINIMUM_LENGTH,
        equalTo: '#password',
      },
      validateNewAccountPasswordConfirmation: {
        required (): boolean {
          return $('#registrationPassword').val() !== '';
        },
        minlength: PASSWORD_MINIMUM_LENGTH,
        equalTo: '#registrationPassword',
      },
      validateRequired: {
        normalizer: function (value: string): string {
          return $.trim(value);
        },
        required (): boolean {
          return true;
        },
      },
      validateBankAccountNo: {
        normalizer: function (value: string): string {
          return $.trim(value);
        },
        bankAccount: true,
      },
      validateBlikAuthCode: {
        normalizer: function (value: string): string {
          return $.trim(value);
        },
        required (): boolean {
          return true;
        },
        minlength: 6,
        maxlength: 6,
        digits: true,
      },
    };
  }

  static validateCellPhone (value: string, element: HTMLElement): boolean {
    if (
      (value === '' || value === '(+48) ___ ___ ___') &&
      !$(element).attr('required')
    ) {
      return true;
    }

    try {
      const phoneNumber = parsePhoneNumberFromString(value, 'PL');
      if (typeof phoneNumber === 'undefined' || !phoneNumber.isValid()) {
        return false;
      }

      if (phoneNumber.getType() !== 'MOBILE') {
        return false;
      }
    } catch (error) {
      if (error instanceof ParseError) {
        return false;
      }
    }

    return true;
  }

  static validateCellPhoneMsg (error: string, element: HTMLElement): string {
    const input = $(element);
    if (input == undefined) {
      return '';
    }

    if (input.val == undefined) {
      return '';
    }

    const inputVal = input.val();

    if (inputVal === undefined) {
      return 'Proszę podać prawidłowy numer telefonu komórkowego';
    }

    if (input.attr('required')) {
      if (inputVal.toString().length === 0) {
        return 'Numer tel. komórkowego jest wymagany';
      }

      if (inputVal.toString() === '(+48) ___ ___ ___') {
        return 'Numer tel. komórkowego jest wymagany';
      }
    }

    return 'Proszę podać prawidłowy numer telefonu komórkowego';
  }

  static validatePhone (value: string, element: HTMLElement): boolean {
    if (
      (value === '' || value === '(+48) ___ ___ ___') &&
      !$(element).attr('required')
    ) {
      return true;
    }

    try {
      const phoneNumber = parsePhoneNumberFromString(value, 'PL');
      if (typeof phoneNumber === 'undefined' || !phoneNumber.isValid()) {
        return false;
      }
    } catch (error) {
      if (error instanceof ParseError) {
        return false;
      }
    }

    return true;
  }

  static validatePhoneMsg (error: JQuery, element: HTMLElement): string {
    const input = $(element);
    if (input.val === undefined) {
      return '';
    }
    const inputVal = input.val();
    if (inputVal === undefined) {
      return '';
    }

    if (input.attr('required')) {
      if (inputVal.toString().length === 0) {
        return 'Numer telefonu jest wymagany';
      }

      if (inputVal.toString() === '(+48) ___ ___ ___') {
        return 'Numer telefonu jest wymagany';
      }
    }

    return 'Proszę podać prawidłowy numer telefonu';
  }

  static validatePostcode (value: string): boolean {
    const regex = RegExp('[0-9]{2}-[0-9]{3}', 'g');
    if (regex.exec(value)) {
      return true;
    }

    return false;
  }

  static validatePostcodeMessage (error: JQuery, element: HTMLElement): string {
    const input = $(element);
    if (input === undefined) {
      return '';
    }
    if (input.val === undefined) {
      return '';
    }

    const inputVal = input.val();
    if (inputVal === undefined) {
      return '';
    }

    if (
      $(element).attr('required') !== undefined &&
      (inputVal.toString().length === 0 || inputVal.toString() === '__-___')
    ) {
      return 'Kod pocztowy jest wymagany';
    }

    return 'Proszę podać prawidłowy kod pocztowy';
  }

  static validateEmail(value: string): boolean {
    const validEmailRegex = new RegExp(/[\w\d\.\-]{1,}@[\w\d\.\-]{1,}\.[\w]{2,}$/);
    if (null === validEmailRegex.exec(value)) {
      return false;
    }

    return true;
  }

  static validateBankAccount (value: string): boolean {
    let trimmedValue = value.trim().replace(/ /g, '');
    trimmedValue = trimmedValue.replace('_', '');
    if (trimmedValue.length < 26) {
      return false;
    }

    return true;
  }

  static validateBankAccountMsg (error: JQuery, element: HTMLElement): string {
    const input = $(element);
    if (input === undefined) {
      return '';
    }
    if (input.val === undefined) {
      return 'Numer konta bankowego musi zawierać 26 znaków.';
    }

    const inputVal = input.val();
    if (inputVal === undefined) {
      return 'Numer konta bankowego musi zawierać 26 znaków.';
    }

    if (
      inputVal.toString().length === 0 ||
      // @TODO Possible bug due to non-input element being checked?
      $(element).toString() === '__ ____ ____ ____ ____ ____ ____'
    ) {
      return 'Numer konta bankowego jest wymagany';
    }

    return 'Numer konta bankowego musi zawierać 26 znaków.';
  }

  static validateNip (value: string): boolean {
    if (value.length < 10) {
      $('#fetchCompanyDataFromGus').prop('disabled', true);
      return false;
    }

    const regex = /[\D]/g;
    const foundNonDigit = value.match(regex);
    if (foundNonDigit !== null) {
      $('#fetchCompanyDataFromGus').prop('disabled', true);
      return false;
    }

    $('#fetchCompanyDataFromGus').prop('disabled', false);

    return true;
  }

  static validateNipMsg (error: JQuery, element: HTMLElement): string {
    const regex = /[\D]/g;
    const elementStr: string = $(element).val() as string;
    if (elementStr.match(regex)) {
      return 'Proszę wpisać NIP w formie samych cyfr';
    }

    if (elementStr.length < 10) {
      return 'Numer NIP musi składać się z 10 cyfr';
    }

    return 'Nieprawidłowy format numeru NIP';
  }

  static validatePasswordChars (value: string): boolean {
    const regexpSmallChar = RegExp('(?=.*[a-z])', 'g');
    const regexpBigChar = RegExp('(?=.*[A-Z])', 'g');
    const regexpNumber = RegExp('(?=.*\\d)', 'g');
    const regexp = RegExp('(?=.*\\d)(?=.*[a-z])(?=.*[A-Z]).*', 'g');
    const passwordLength = $('#passwordLength');
    const passwordSmallChar = $('#passwordSmallChar');
    const passwordBigChar = $('#passwordBigChar');
    const passwordNumber = $('#passwordNumber');

    PASSWORD_MINIMUM_LENGTH <= value.length ?
      passwordLength.addClass('is-valid') : passwordLength.removeClass('is-valid');
    regexpSmallChar.exec(value) ?
      passwordSmallChar.addClass('is-valid') : passwordSmallChar.removeClass('is-valid');
    regexpBigChar.exec(value) ?
      passwordBigChar.addClass('is-valid') : passwordBigChar.removeClass('is-valid');
    regexpNumber.exec(value) ?
      passwordNumber.addClass('is-valid') : passwordNumber.removeClass('is-valid');

    return PASSWORD_MINIMUM_LENGTH <= value.length && !!regexp.exec(value);
  }
}

export { ValidationSettings, Validation };
