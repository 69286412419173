import '../../scss/entries/_home.scss';
import '@webcomponents/custom-elements';
import $ from 'jquery';
import 'jquery-validation';
import 'jquery-validation/dist/localization/messages_pl';
import 'bootstrap/js/src/collapse';
import 'popper.js';
import 'bootstrap/js/src/tab';
import 'bootstrap/js/src/popover';
import 'bootstrap/js/src/tooltip';
import 'bootstrap/js/src/dropdown';
import 'bootstrap/js/src/modal';
import 'bootstrap/js/src/util';
import '../user-notifications';
import '../base.ts';
import '../homeNewsletter.ts';
import '../main-swiper.ts';
import '../ga4-tracking.ts';
import '../validation';
import '../ajaxCart';
import '../body-class.ts';
import { Site } from '../site';
import '../mobile.ts';
import loadRecaptchaWhenInView from '../common/loadRecaptchaWhenInView';
import trackCatalogClickToGA from '../common/trackCatalogClickToGA';
import trackSuggestionsClickToGA from '../common/trackSuggestionsClickToGA';
import landingPageDynamicComponents from '../components/landing-page-dynamic-components';
import '../components/landing-page-dynamic-components/components/cms-dynamic-card';
import '@/js/mailSimilarity/mailSuggestions.ts';

require('intersection-observer');

const moreIcon = `<svg class="icon more-icon  icon-inline " aria-hidden="true">
    <use xlink:href="#more"></use>
</svg>`;

function mobilePopularCategoriesControl (): void {
  const systemMenu = $('#systemMenu');

  // Using workaround for Safari bug - toggleClass does not work.
  $('.system-menu-open').on('click', function (e) {
    e.stopImmediatePropagation();
    if (!systemMenu.hasClass('show')) {
      systemMenu.addClass('show');
    } else {
      systemMenu.removeClass('show');
    }
  });

  $('#systemMenuClose').on('click', function (e) {
    e.preventDefault();
    e.stopPropagation();
    $('#systemMenu').removeClass('show');
  });

  $('.pop-cat-trigger a').on('click', function (e) {
    e.preventDefault();
    $('.system-menu-open').html($(this).text() + moreIcon);
    $('#systemMenu').removeClass('show');
    $('.pop-cat-trigger a').each(function (i, el) {
      $(el).removeClass('active');
    });
    $(this).tab('show');
    $(this).addClass('active');
  });
}

function calculateCutOffPopularCategories (): void {
  const navItems = $('#popularCategoriesTab').find('.nav-item');
  const itemsToMove = [];
  navItems.each(function (i, el): void {
    let cutOffFrom = 5;
    const windowWidth = $(window).width() || 0;
    if (windowWidth > 1920) {
      cutOffFrom = 10;
    }
    if (windowWidth <= 1485 && windowWidth >= 1351) {
      cutOffFrom = 5;
    }
    if (windowWidth <= 1350) {
      cutOffFrom = 4;
    }
    if (windowWidth <= 1190) {
      cutOffFrom = 3;
    }

    if (i >= cutOffFrom && $(el).hasClass('dropdown') === false) {
      itemsToMove.push(el);
      $(el).addClass('item-hidden');
    } else {
      $(el).removeClass('item-hidden');
    }
  });
}

$(function (): void {
  loadRecaptchaWhenInView();
  trackCatalogClickToGA('strona główna');
  landingPageDynamicComponents();

  const suggestionsBox = document.querySelector<HTMLElement>('.suggestions-box');
  suggestionsBox && trackSuggestionsClickToGA(
    'strona główna',
    suggestionsBox,
  );

  $(window).on('resize', function () {
    calculateCutOffPopularCategories();
    if (
      (Site.isMobile && Site.isTouchDevice)
    ) {
      mobilePopularCategoriesControl();
    }
  });

  if (Site.isChecked === false) {
    Site.bodyClass();
  }

  if (
    (Site.isMobile && Site.isTouchDevice)
  ) {
    mobilePopularCategoriesControl();
  } else {
    calculateCutOffPopularCategories();
  }

  $('#popularCategoriesTab').on('shown.bs.tab', function (e) {
    $('#popularCategoriesTab .nav-item.dropdown .dropdown-item').removeClass(
      'active',
    );
    $('#popularCategoriesTab .nav-link').removeClass('active');
    $(e).addClass('active');
    const link = $(e.target).attr('href');
    $(`a[href="${link}"]`).each(function () {
      $(this).addClass('active');
    });
  });

  $('#newsletterSignupBtn').tooltip({
    html: true,
    placement: 'top',
    template:
      '<div class="tooltip custom-tooltip"><div class="arrow"></div>' +
      '<div class="tooltip-inner"></div></div></div>',
  });
});
