import $ from 'jquery';

export const circleLoader = {
  init (): void {
    $(document).on('ajaxStart', function () {
      circleLoader.show();
    });
    $(document).on('ajaxStop', function () {
      circleLoader.hide();
    });
    $(document).on('ajaxError', function () {
      circleLoader.hide();
    });
  },
  show (): void {
    $('#ajaxLoader').css('display', 'flex');
  },
  hide (): void {
    $('#ajaxLoader').css('display', 'none');
  },
};

$(function (): void {
  $(document).on('ajaxStart', function () {
    circleLoader.show();
  });
  $(document).on('ajaxStop', function () {
    circleLoader.hide();
  });
  $(document).on('ajaxError', function () {
    circleLoader.hide();
  });
});
