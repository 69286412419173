import _ from 'lodash';
import {
  CMSDynamicComponentEnum,
  IntersectionQueue,
  ListingSourceUrl,
} from './types';
import { LP_FETCH_DEBOUNCE } from './const';
import { dynamiComponentService } from './api';
import { frontApi } from '@/api/useFrontendApi';

export default function (): void {
  const intersectionQueue: IntersectionQueue = {};
  let fetchDebounce: ReturnType<typeof setTimeout>;

  const trackComponents: IntersectionObserverCallback = (
    elements,
  ): void => elements.forEach((item) => {
    const componentId = item.target.getAttribute('url');

    let link: string = decodeURIComponent(window.atob(componentId ?? '')) ?? '';
    const hasQueryParameters = link.includes('?');
    const separator = hasQueryParameters ? '&' : '?';
    const limit = item.target.getAttribute('limit');
    if (link) {
      if (limit) {
        link = link + `${separator}showPerPage=${limit}`;
      } else if (!link.includes('showPerPage')) {
        link = link + `${separator}showPerPage=36`;
      }
      link += '&hasImage=1';
      link = window.btoa(encodeURIComponent(link));
    }

    if (!link) {
      item.target.setAttribute('render', '1');
      return;
    }

    if (
      item.isIntersecting &&
      !item.target.hasAttribute('state')
    ) {
      if (intersectionQueue[link]) {
        intersectionQueue[link].target.push(item.target as HTMLElement);
      } else {
        intersectionQueue[link] = {
          id: link,
          target: [item.target as HTMLElement],
        }
      }
    }

    if (Object.keys(intersectionQueue).length > 0) {
      clearTimeout(fetchDebounce);
      fetchDebounce = setTimeout(
        () => fetch(),
        LP_FETCH_DEBOUNCE,
      );
    }
  });

  const fetch = async (): Promise<void> => {
    const batch: ListingSourceUrl[] = [];
    const entries = Object.entries(intersectionQueue).splice(0, 10);

    entries.forEach((item) => {
      batch.push(item[1].id);
      delete intersectionQueue[item[0]];
    });

    try {
      const res = await frontApi.landingPage.searchProductsByUrls({urlArray: batch});
      entries.forEach((item) => {
        const [key, element] = item;
        if (!res[key] || !res[key].products || res[key].products.length === 0) return;
        element.target.forEach((target) => {
          switch (target.nodeName.toLowerCase()) {
          case CMSDynamicComponentEnum.Card:
            if (res[key].products[0]) {
              target.setAttribute(
                'state',
                JSON.stringify(res[key].products[0]),
              );
            }
            break;
          }
        })
      });
    } catch (e) {
      // TODO: handle error by alert
      console.error(e);
    }
  }

  const observer = new IntersectionObserver(
    trackComponents,
    {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    },
  );

  window.lpDynamicObserve.forEach(
    (element) => {
      observer.observe(element);
    },
  );

  /**
   * Proxy will handle all lazy loaded dynamic components
   */
  window.lpDynamicObserve = new Proxy(window.lpDynamicObserve, {
    set (
      target,
      prop,
      value,
    ): boolean {
      observer.observe(value);
      return true;
    },
  });
}
