
export default function (
  place: string,
): void {
  const LAYOUT_WITH_CATALOGS_ID = '7522b013-d51d-4b6b-b523-8cd2d284f6ea';
  const LAYOUT_WITH_CATALOGS_ID_DESKTOP = '08bf7a65-9f33-433f-9842-7d541ffa3dcf';
  const layoutIds = [LAYOUT_WITH_CATALOGS_ID, LAYOUT_WITH_CATALOGS_ID_DESKTOP];
  layoutIds.forEach((id) => {
    document.querySelectorAll(`[data-layout-id="${id}"] .cms-component a`).forEach((element) => {
      const label = element.querySelector('img')!.getAttribute('alt')!;
      label.length > 0 && element.addEventListener(
        'click',
        () => window.pushDataLayer({
          event: 'ux_events',
          event_place: place,
          event_category: 'katalogi',
          event_label: label,
        }),
      );
    });
  });
}
