import $ from 'jquery';
import URI from 'urijs';
import openBodyOverflow from '@/js/openBodyOverflow';
import blockBodyOverflow from '@/js/blockBodyOverflow';
import { Site } from '@/js/site';


$(function (): void {
  const mobileCategoriesMenu = $('#mobileCategoriesMenu');
  const filtersMenu = $('#filtersMenu');
  const filtersMenuToggleInside = $('#filtersMenuToggleInside');
  const mobileSearchInput = $('#mobileSearch');
  const clearSearchInputBtn = $('#clearSearchInput');
  const showMoreCategories = $('#showMoreCategories');
  const backToTop = $('#backToTop');

  filtersMenuToggleInside.on('click', function () {
    filtersMenu.modal('hide');
  });

  filtersMenu.on('shown.bs.modal', function () {
    blockBodyOverflow();
    backToTop.addClass('d-none');
    filtersMenuModal.scrollTop(0);
  })

  filtersMenu.on('hide.bs.modal', function () {
    openBodyOverflow();
    backToTop.removeClass('d-none');
  })

  $(window).on('resize', function () {
    if (Site.breakpoints.md <= Site.windowWidth) {
      filtersMenu.modal('hide');
    }
  })

  showMoreCategories.on('click', function (e) {
    e.preventDefault();
    const buttonText = showMoreCategories.text();
    const showMoreText = 'WIĘCEJ KATEGORII';
    const showLessText = 'MNIEJ KATEGORII';
    showMoreCategories.text(buttonText === showMoreText ? showLessText : showMoreText);
  });

  const searchMenu = $('#mobileSearchMenu');
  searchMenu.on('show.bs.modal', function () {
    mobileCategoriesMenu.modal('hide');
    blockBodyOverflow();
    backToTop.addClass('d-none');
  })

  searchMenu.on('shown.bs.modal', function () {
    blockBodyOverflow();
    backToTop.addClass('d-none');
    mobileSearchInput.trigger('focus');
  })

  searchMenu.on('hide.bs.modal', function () {
    openBodyOverflow();
    backToTop.removeClass('d-none');
    mobileSearchInput.val('');
    clearSearchInputBtn.addClass('d-none');
  });

  const toggleSidebar = $('#toggleSidebar');
  const filtersMenuModal = $('#filtersMenu .modal-content');
  toggleSidebar.on('click', function () {
    filtersMenu.modal('show');
  });

  $('#goBack').on('click', function (e) {
    e.preventDefault();

    let useFallback = true;

    window.addEventListener('beforeunload', function () {
      useFallback = false;
    });

    window.history.back();

    setTimeout(function () {
      if (useFallback) {
        const uri = new URI(location.href);
        console.log(uri.origin());
        window.location.href = uri.origin();
      }
    }, 100);

    return false;
  });
});
