import Route from '@/js/utils/route';
import $ from 'jquery';
import SiteInterface from './interface/SiteInterface';

const Site = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1920,
  },
  addToCartUrl: '/koszyk/dodaj',
  body: $('body'),
  windowWidth: $(window).width(),
  windowHeight: $(window).height(),
  isIos: false,
  isMobile: false,
  isTablet: false,
  isDesktop: false,
  isTouchDevice: window.matchMedia('(hover: none) and (pointer: coarse)')
    .matches,
  isMouseDevice: window.matchMedia('(hover: hover) and (pointer: fine)')
    .matches,
  isMobileMenuVisible: false,
  siteType: '',
  baseUrl: '',
  isChecked: false,
  imgPlaceholderUrl: '',
  authorAutocompleteUrl: '',
  publisherAutocompleteUrl: '',
  searchAutocompleteUrl: '',
  changeDefaultAddressUrl: '',
  changeDefaultAddressPointUrl: '',
  changeDefaultInvoiceUrl: '',
  orientation: 'landscape',
  bodyClass: function (): void {
    Site.isChecked = true;

    if (Site.windowWidth === undefined) {
      return;
    }

    if (Site.body.hasClass('mobile-device')) {
      Site.isMobile = true;
    }

    if (Site.body.hasClass('tablet-device')) {
      Site.isTablet = true;
    }

    if (Site.body.hasClass('desktop')) {
      Site.isDesktop = true;
    }

    if (Site.body.hasClass('ios-device')) {
      Site.isIos = true;
    }

    Site.body.attr('data-window-width', Site.windowWidth);
    if (Site.windowHeight !== undefined) {
      Site.body.attr('data-window-height', Site.windowHeight);
    }
    Site.body.attr('data-orientation', Site.orientation);
  },
  enableLoading: (): void => {
    Site.body.data('isLoading', '1');
  },
  disableLoading: (): void => {
    Site.body.data('isLoading', '0');
  },
  isLoading: (): boolean => {
    return Site.body.data('isLoading') === '1';
  },
} as SiteInterface;

$(function (): void {
  Site.siteType = $('body').data('siteType') as string;
  if (Site.windowWidth === undefined || Site.windowHeight === undefined) {
    return;
  }
  Site.body.attr('data-window-width', Site.windowWidth);
  Site.body.attr('data-window-height', Site.windowHeight);

  if (Site.windowWidth >= Site.windowHeight) {
    Site.orientation = 'landscape';
  } else {
    Site.orientation = 'portrait';
  }
  Site.body.attr('data-orientation', Site.orientation);
  $(window).on('resize', function () {
    Site.windowWidth = $(this).width() || 0;
    Site.windowHeight = $(this).height() || 0;
    if (Site.windowWidth >= Site.windowHeight) {
      Site.orientation = 'landscape';
    } else {
      Site.orientation = 'portrait';
    }
    Site.isTouchDevice = window.matchMedia(
      '(hover: none) and (pointer: coarse)',
    ).matches;
    Site.isMouseDevice = window.matchMedia(
      '(hover: hover) and (pointer: fine)',
    ).matches;
    Site.bodyClass();
  });
  Site.authorAutocompleteUrl = Route.get('search.author.suggestions');
  Site.publisherAutocompleteUrl = Route.get('search.publisher.suggestions');
  Site.searchAutocompleteUrl = Route.get('search.suggestions');
});

export { Site };
