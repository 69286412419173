import { HomeNewsletterValidationSettings } from './HomeNewsletterValidationSettings';
import $ from 'jquery';

$(function (): void {
  const homeNewsletterForm = $('#homeNewsletterForm');
  if (homeNewsletterForm.length > 0) {
    const settings = HomeNewsletterValidationSettings.getSettings();
    homeNewsletterForm.validate(settings);

    $('#newsletterSignupBtn').on('click', function (e) {
      e.preventDefault();
      homeNewsletterForm.trigger('submit');
    });
  }
});
