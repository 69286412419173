import { LandingPageSwiperOptions as SwiperOptions } from '@/api/generated';
import { CardOptions } from '@/api/generated';
import { wrapAsset } from '@/js/utils/assets';
import _ from 'lodash';

export abstract class CMSDynamicComponent<
  SwiperOptions,
  CardOptions
> extends HTMLElement {
  protected props?: SwiperOptions;
  protected state?: CardOptions;

  static get observedAttributes (): string[] {
    return ['state', 'render'];
  }

  attributeChangedCallback (name: string, oldValue: string, newValue: string): void {
    switch (name) {
    case 'state':
      this.state = JSON.parse(newValue) as CardOptions;
      this.render();
      break;
    case 'render':
      this.render();
      break;
    }
  }

  setState (newState: Partial<CardOptions|SwiperOptions>): void {
    _.merge(this.state, newState);
  }

  insertPlaceholder (
    delay: 'd1' | 'd2' | undefined = undefined,
    withLogo = false,
  ): string {
    return `
    <span class="cms-dynamic__placeholder">
      <span class="cms-dynamic__placeholder-bg${delay ? ` -${delay}` : ''}">
        ${withLogo ? `
        <span 
          style="background-image:url(${wrapAsset('/assets/images/logo.svg')})"
        ></span>
        `: ''}
      </span>
    </span>
    `;
  }

  abstract render(): void;
}
