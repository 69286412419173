import $ from 'jquery';
import { GoogleAnalyticsItem } from '@/typings/google-analytics';

$(function () {
  $('.swiper-slide').on('click', '.slide-img', function (): void {
    const productCard = $(this).closest('.swiper-slide')
    sendSelectItemEvent(productCard);
  });

  $('.swiper-slide').on('click', '.product-name-link', function (): void {
    const productCard = $(this).closest('.swiper-slide')
    sendSelectItemEvent(productCard);
  });

  $('.product-card').on('click', '.product-img-container', function (): void {
    const productCard = $(this).closest('.product-card')
    sendSelectItemEvent(productCard);
  });

  $('.product-card').on('click', '.product-name', function (): void {
    const productCard = $(this).closest('.product-card')
    sendSelectItemEvent(productCard);
  });

  $('.set-product').on('click', '.product-image-link', function (): void {
    const productCard = $(this).closest('.set-product')
    sendSelectItemEvent(productCard);
  });

  $('.set-product').on('click', '.product-name-link', function (): void {
    const productCard = $(this).closest('.set-product')
    sendSelectItemEvent(productCard);
  });

  function sendSelectItemEvent (productCard: JQuery<HTMLElement>): void {
    const listId = productCard.data('list-id') as string;
    const listName = productCard.data('list-name') as string;
    const itemId = productCard.data('item-id') as string;
    const itemName = productCard.data('item-name') as string;
    const itemPrice = productCard.data('item-price') as number;
    const itemCategory = productCard.data('item-category') as string;
    const itemAuthors = productCard.data('item-authors') as string;

    if (!listId || !listName || !itemId || !itemName || !itemPrice) {
      return;
    }

    const item = {
      'item_id': itemId,
      'item_name': itemName,
      'price': itemPrice,
    } as GoogleAnalyticsItem;

    if (itemCategory) {
      item['item_category'] = itemCategory;
    }
    if (itemAuthors) {
      item['item_brand'] = itemAuthors;
    }

    window.pushDataLayer({
      event: 'select_item',
      ecommerce: {
        item_list_id: listId,
        item_list_name: listName,
        items: [
          item,
        ],
      },
    });
  }
});
