import Route from '@/js/utils/route';
import $ from 'jquery';
import { circleLoader } from '../circleLoader';
import { Site } from '../site';
import RemoveFromCartModalResponse from '../interface/RemoveFromCartModalResponse';
import { UICartUpdateEventSource } from '@/ui/composable/useCart';
import * as Cookies from 'es-cookie';

export default function removeFromCartModal (
  itemId: string,
  pageType: string,
  buttonType: string,
  buttonCopy: string,
): void {
  const total = Number(
    (
      document.querySelector(
        `.row-total-${itemId}`,
      ) as HTMLElement
    )?.textContent?.replace(',', '.').replace(/[^0-9.]/g, '') ?? 0,
  );

  void $.ajax({
    type: 'delete',
    url: Route.get('remove-from-cart-modal'),
    headers: {'X-XSRF-TOKEN': Cookies.get('XSRF_TOKEN') ?? '' },
    data: {
      itemId: itemId,
      pageType: pageType,
      buttonType: buttonType,
      buttonCopy: buttonCopy,
      location: window.location.href,
    },
    dataType: 'json',
    success: function (response: RemoveFromCartModalResponse) {
      const addedToCartModal = $('#addedToCartModal');
      addedToCartModal.modal('hide');

      $('.mobile-cart-count').html(`(${response.cartCount})`);
      $('.cart-count').html(`(${response.cartCount})`);

      typeof response.cartTotal !== 'undefined' &&
      response.cartTotal >= 0 && document.dispatchEvent(new CustomEvent('cart_update', {
        detail: {
          cartCount: response.cartCount,
          cartTotal: response.cartTotal,
          source: UICartUpdateEventSource.Cart,
        },
      }));
    },
    error: function () {
      location.reload();
    },
    // called when the request finishes (after success and error callbacks are executed)
    complete: function () {
      circleLoader.hide();
    },
  });
}
