import $ from 'jquery';
import Route from '@/js/utils/route';
import { Site } from './site';
import { Alert } from './alert';
import { circleLoader } from './circleLoader';
import jqXHR = JQuery.jqXHR;
import useModal from '@/ui/composable/useModal';
import * as Cookies from 'es-cookie';

export function addUserNotification (
  productId: number,
): void {
  void $.ajax({
    type: 'post',
    url: Route.get('user-product-notification-add'),
    headers: {'X-XSRF-TOKEN': Cookies.get('XSRF_TOKEN') ?? '' },
    data: {
      productId: productId,
    },
    dataType: 'json',
    success: function () {
      Alert.changeType('success');
    },
    error: function () {
      Alert.changeType('danger');
    },
    complete: function (data: jqXHR) {
      circleLoader.hide();
      const response = data.responseJSON as { message?: string };
      Alert.replaceMessage(response.message || 'Wystąpił błąd');
      Alert.show();
    },
  });
}

$(function (): void {
  const { toggleAsyncModal } = useModal();
  $(document).on('click', '.btn-add-to-notifications', async (e) => {
    const productId: number = parseInt($(e.target).data('productId') as string);
    console.log('productId', productId);

    if (!window.auth) {
      await toggleAsyncModal('ui-login-modal', 'ui-login-modal', {
        redirect: window.location.href,
        payload: {
          event: 'product_notify_when_available',
          payload: productId,
        },
      });
      return;
    }

    document.dispatchEvent(new CustomEvent(
      'product_notify_when_available',
      {
        'detail': {
          productId,
        },
      },
    ));
  });
});
