import { Site } from './site';

document.addEventListener(
  'DOMContentLoaded',
  function () {
    if (document != null) {
      const body = document.querySelector('body');
      if (body != null) {
        if (typeof body.dataset.siteType !== 'undefined') {
          Site.siteType = body.dataset.siteType;
        }
      }
    }
  },
  {passive: true},
);
