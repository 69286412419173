import { domains } from '@/js/const/mailSimilarityDomains';
import { findSimilarDomain } from '@/js/mailSimilarity/findSimilarMailDomain';

document.addEventListener('DOMContentLoaded', function () {
  const emailInputs = document.querySelectorAll('input[type="email"]');
  emailInputs.forEach((emailInput) => {
    if (
      emailInput !== null &&
      'value' in emailInput
    ) {
      emailInput.addEventListener('input', function () {
        const inputValue = String(emailInput.value);
        const atIndex = inputValue.indexOf('@');
        const inputName = emailInput.getAttribute('id');
        const emailSuggestions = document.querySelector(`[data-list-for-input="${inputName}"]`);

        const suggestionContainer = document.querySelector(`[data-input-id="${inputName}"]`) as HTMLElement;

        if (!suggestionContainer || !emailSuggestions) {
          return;
        }

        const suggestionMail = suggestionContainer.querySelector('.suggestion__mail') as HTMLElement;
        const addSuggestionMailBtn = suggestionContainer.querySelector('.suggestion__button') as HTMLElement;

        if (atIndex !== -1) {
          const typedDomain = inputValue.slice(atIndex + 1);
          const matchingDomains = domains.filter((domain) => domain.startsWith(typedDomain));
          emailSuggestions.innerHTML = '';

          matchingDomains.forEach((domain) => {
            const option = document.createElement('option');
            option.value = inputValue.slice(0, atIndex + 1) + domain;
            emailSuggestions.appendChild(option);
          });

          const similarDomain = findSimilarDomain(typedDomain);
          if (similarDomain && similarDomain !== typedDomain) {
            suggestionMail.textContent = `Czy chodziło Ci o 
          ${inputValue.slice(0, atIndex + 1)}${similarDomain}?`;

            addSuggestionMailBtn.addEventListener('click', function () {
              emailInput.value = inputValue.slice(0, atIndex + 1) + similarDomain;
              suggestionContainer.style.display = 'none';
            })
            suggestionContainer.style.display = 'block';
          } else {
            suggestionContainer.style.display = 'none';
          }
        } else {
          emailSuggestions.innerHTML = '';
          suggestionContainer.style.display = 'none';
        }
      });
    }
  });
});
