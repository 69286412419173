import Inputmask from 'inputmask';

export const ADDED_FROM_SEARCH_RESULT = 1;

export const ADDED_FROM_PRODUCT_VIEW = 2;

export const ADDED_FROM_PRODUCT_VIEW_SET = 3;

export const ADDED_FROM_USER_LIST = 4;

export const CASH_ON_DELIVERY_PAYMENT_ID = 1;

export const INPUT_MASK_PHONE = '(+48) 999 999 999';
export const INPUT_MASK_PHONE_OPTS: Inputmask.Options = {
  autoUnmask: true,
  inputmode: 'tel',
  greedy: false,
};
export const INPUT_MASK_BANK_ACC = '99 9999 9999 9999 9999 9999 9999';
export const INPUT_MASK_BANK_ACC_OPTS: Inputmask.Options = {
  autoUnmask: true,
};

export const ADDRESS_POINT_TYPE_RUCH = 1;
export const ADDRESS_POINT_TYPE_PACZKOMAT = 2;
export const ADDRESS_POINT_TYPE_POCZTA = 3;
export const ADDRESS_POINT_TYPE_DPD = 4;

export const PAYMENT_BLIK_ID = 7;

export const FILTER_DEBOUNCE = 1750;
export const GTM_EVENT_FILTER_SEPARATOR = ' / ';
export const GTM_EVENT_FILTER_VALUE_SEPARATOR = ' / ';

export const BESTSELLER_TAG_ID = 28;
export const NEWS_TAG_ID = 27;
