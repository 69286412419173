import { getImageThumb } from '@/js/utils/assets';
import {
  DynamicCardComponent,
  DynamicCardComponentProps,
  FlexAlignEnum,
  FlexDirectionEnum,
} from '../types';
import { ImageThumbsEnum } from '@/js/typings/imageThumbs';
import { CMSDynamicComponent } from '.';
import relativePathIfInternal from '@/ui/utils/relativePathIfInternal';

class CMSDynamicCard extends CMSDynamicComponent<
  DynamicCardComponentProps,
  DynamicCardComponent
> {
  constructor () {
    super();
    const props = this.getAttribute('props');

    if (!props) return;

    this.props = JSON.parse(props) as DynamicCardComponentProps;
    const modificators: string[] = [];

    this.props.flexDirection === FlexDirectionEnum.ColumnReverse &&
      modificators.push(`-cmsd--img-bottom`);
    this.props.flexDirection === FlexDirectionEnum.RowReverse &&
      modificators.push(`-cmsd--img-right`);
    this.props.flexDirection === FlexDirectionEnum.Row &&
      modificators.push(`-cmsd--img-left`);

    this.props.alignItems === FlexAlignEnum.Start &&
      modificators.push(`-cmsd--align-items-${this.props.alignItems}`);
    this.props.alignItems === FlexAlignEnum.Center &&
      modificators.push(`-cmsd--align-items-${this.props.alignItems}`);
    this.props.alignItems === FlexAlignEnum.End &&
      modificators.push(`-cmsd--align-items-${this.props.alignItems}`);
    this.props.alignItems === FlexAlignEnum.Stretch &&
      modificators.push(`-cmsd--align-items-${this.props.alignItems}`);

    this.props.justifyContent === FlexAlignEnum.Start &&
      modificators.push(`-cmsd--justify-content-${this.props.justifyContent}`);
    this.props.justifyContent === FlexAlignEnum.Center &&
      modificators.push(`-cmsd--justify-content-${this.props.justifyContent}`);
    this.props.justifyContent === FlexAlignEnum.Stretch &&
      modificators.push(`-cmsd--justify-content-${this.props.justifyContent}`);
    this.props.justifyContent === FlexAlignEnum.End &&
      modificators.push(`-cmsd--justify-content-${this.props.justifyContent}`);

    const cardText = this.props?.cardText?.length ?? 0 > 0 ?
      `<span class="-cmsd-text">${this.props?.cardText ?? ''}</span>` :
      '';

    const alignEndPlaceholder = this.props.alignItems === FlexAlignEnum.End ?
      '<span>&nbsp;</span>' :
      '';

    this.innerHTML = `
    <a class="cms-dynamic-card ${modificators.filter((item) => item).join(' ')}">
      <span class="-cmsd-image">
        ${this.insertPlaceholder(undefined, true)}
      </span>
      <span class="-cmsd-content">
        ${alignEndPlaceholder}
        <span class="-cmsd-title">
          ${this.props.cardTitleAutofill ? this.insertPlaceholder() : this.props.cardTitle}
        </span>
        <span class="-cmsd-subtitle">
          ${this.props.cardSubtitleAutofill ? this.insertPlaceholder() : this.props.cardSubtitle}
        </span>
        ${cardText}
        ${this.props.showMoreLink ? `
        <span class="-cmsd-show-more">Zobacz więcej</span>
        ` : ''}
      </span>
    </a>`;

    window.lpDynamicObserve.push(this);
  }

  render (): void {
    if (this.state?.url || this.props?.cardLink) {
      this.querySelector<HTMLAnchorElement>(
        '.cms-dynamic-card',
      )?.setAttribute(
        'href',
        relativePathIfInternal(this.props?.cardLink || this.state?.url) || '#',
      );
    }

    if (this.props?.cardTitleAutofill) {
      this.querySelector('.-cmsd-title')!.textContent =
        this.state?.name ?? this.props.cardTitle ?? '';
    }

    if (this.props?.cardSubtitleAutofill) {
      this.querySelector('.-cmsd-subtitle')!.textContent =
        this.state?.authors[0]?.name ?? this.props.cardSubtitle ?? '';
    }

    const image = this.querySelector('.-cmsd-image') as HTMLElement;
    const img = document.createElement('img');
    const preload = new Image();
    const src = this.state?.imageUrl ?? getImageThumb(
      this.props?.cardImage?.imageUrl as string,
      ImageThumbsEnum.Small,
    );
    preload.src = src;
    preload.onload = (): void => {
      img.src = src;
      image.firstElementChild?.replaceWith(img);
      img.onload = (): void => {
        image.style.height = `${img.getBoundingClientRect().height}px`;
      }
    };
  }
}

customElements.define('cms-dynamic-card', CMSDynamicCard);
