import { Alert } from './alert';
import newsletterSignup from './newsletterSignup';
import { ValidationSettings } from './ValidationSettings';
import $ from 'jquery';

class Validation {
  static addMessageToInput (
    invalidInput: JQuery,
    key: string,
    value: string,
  ): void {
    invalidInput.addClass('is-invalid');
    invalidInput.removeClass('is-valid');
    const label = $(`label[for=${String(key)}]`);
    const validationMsg = $(
      `<div class="help-block is-invalid">` + value + `</div>`,
    );
    validationMsg.insertAfter(label);
  }
}

class HomeNewsletterValidationSettings extends ValidationSettings {
  static getSettings (): JQueryValidation.ValidationOptions {
    return {
      ...this.getBaseSettings(),
      submitHandler: function (): boolean {
        const email = String($('input#newsletterEmail').val());
        const siteKey = $('body').data('siteKey') as string;
        grecaptcha.ready(function () {
          void grecaptcha
            .execute(siteKey, {action: 'homeNewsletterSign'})
            .then(function (token) {
              if (!token) {
                Alert.changeType('danger');
                Alert.replaceMessage(
                  'Wystąpił błąd podczas zapisu do newslettera. Odśwież stronę i spróbuj ponownie.',
                );
                Alert.show();
                console.error('Captcha test failed');

                return false;
              }
              newsletterSignup(email, token);
            });
        });
        return false;
      },
    };
  }
}

export { HomeNewsletterValidationSettings, Validation };
